import { Atom } from ":mods";

export function createLanguageInfoInputs() {
  const speak_arabic = Atom.Form.createInput("", {
    required: true,
  });
  const arabic_level = Atom.Form.createInput("", {
    required: false,
  });
  const english_level = Atom.Form.createInput("", {
    required: false,
  });
  const speak_english = Atom.Form.createInput("", {
    required: true,
  });
  
  


  const Actions = Atom.Form.createFormActions({
    speak_arabic,
    arabic_level,
    speak_english,
    english_level
  });
  return {
    Actions,
    speak_arabic,
    arabic_level,
    speak_english,
    english_level
  };
}
